import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Drawer, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import image_logo from "../../assets/site/logo.png";
import dadashree_logo from "../../assets/site/landing/dada_4.webp";
import { useNavigate } from "react-router";
import { MediaImage } from "../../common/typography";

const navBtnList = [
    {
        label: "Home",
        navPath: "/",
        pageName: "home",
    },
    {
        label: "About US",
        navPath: "/public/aboutus",
        pageName: "aboutus",
    },
    {
        label: "Events",
        navPath: "/public/events",
        pageName: "events",
    },
    {
        label: "Shreekalp",
        navPath: "/public/shreekalp",
        pageName: "shreekalp",
    },
    {
        label: "Login / Signup",
        navPath: "/registration/lawyer",
        pageName: "login",
    },
];

const NavigationBtns = ({ currentPage }) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: { xs: "none", md: "flex" },
                columnGap: "5px",
            }}
        >
            {navBtnList.map((navBtnItem, index) => (
                <Button
                    sx={{
                        background:
                            currentPage === navBtnItem.pageName
                                ? "#b714bd"
                                : "#6d2185",
                        fontSize: "12px",
                        borderRadius: 4,
                        "&:hover": {
                            backgroundColor: "#b714bd",
                        },
                    }}
                    onClick={() => {
                        navigate(navBtnItem.navPath);
                    }}
                    variant="contained"
                    size="small"
                >
                    {navBtnItem.label}
                </Button>
            ))}
        </Box>
    );
};

export const Header = ({ currentPage = "home" }) => {
    const navigate = useNavigate();
    const [menuDrawerState, setMenuDrawerState] = useState(false);
    return (
        <Box
            sx={{
                height: { xs: "110px", sm: "140px" },
                background: "#552762",
                display: "flex",
                alignItems: "center",
                position: "relative",
            }}
        >
            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0 5px",
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "auto 1fr auto auto",
                            md: "auto 1fr auto",
                        },
                        alignItems: "center",
                        columnGap: { xs: "5px", sm: "20px" },
                    }}
                >
                    <MediaImage
                        src={image_logo}
                        sx={{
                            width: { xs: "71px", sm: "110px" },
                            height: { xs: "70px", sm: "110px" },
                        }}
                        alt="Divine Harmony Logo"
                    />

                    <Box
                        sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                letterSpacing: 4,
                                columnGap: "15px",
                                flexDirection: { xs: "column", md: "row" },
                                alignItems: { xs: "left", md: "flex-end" },
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                component="h3"
                                variant="h3"
                                sx={{
                                    fontSize: { xs: "16px", md: "30px" },
                                    color: "#fff",
                                    fontFamily: "caudex,serif",
                                }}
                            >
                                Divine Harmony
                            </Typography>
                            <Typography
                                component="h3"
                                variant="h3"
                                sx={{
                                    fontSize: { xs: "14px", md: "20px" },
                                    marginLeft: "auto",
                                    color: "#FF4FED",
                                    fontFamily: "cursive, Libre Baskerville",
                                    letterSpacing: "normal",
                                }}
                            >
                                -with Shreekalp
                            </Typography>
                        </Box>
                        <NavigationBtns currentPage={currentPage} />
                    </Box>

                    <MediaImage
                        src={dadashree_logo}
                        sx={{
                            width: { xs: "90px", sm: "128px" },
                            height: { xs: "90px", sm: "137px" },
                        }}
                    />

                    <IconButton
                        sx={{ display: { xs: "block", md: "none" } }}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        fontSize="30px"
                        onClick={() => setMenuDrawerState(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
            </Container>
            <Drawer
                anchor={"right"}
                open={menuDrawerState}
                PaperProps={{ sx: { background: "#000000b3" } }}
                onClose={() => setMenuDrawerState(false)}
            >
                <Box
                    sx={{
                        width: 250,
                    }}
                    role="presentation"
                    onClick={() => setMenuDrawerState(false)}
                    onKeyDown={() => setMenuDrawerState(false)}
                >
                    <Box
                        sx={{
                            display: "flex",
                            rowGap: "5px",
                            flexDirection: "column",
                            padding: "15px 0",
                        }}
                    >
                        {navBtnList.map((navBtnItem, index) => (
                            <Button
                                variant="contained"
                                sx={{
                                    color: "white",
                                    height: "56px",
                                    borderRadius: 0,

                                    backgroundImage:
                                        "linear-gradient(60deg, #29323c 0%, #485563 100%)",
                                    background:
                                        currentPage === navBtnItem.pageName
                                            ? "#b714bd"
                                            : undefined,
                                    "&:hover": {
                                        backgroundColor: "#b714bd",
                                    },
                                }}
                                onClick={() => {
                                    navigate(navBtnItem.navPath);
                                }}
                            >
                                {navBtnItem.label}
                            </Button>
                        ))}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};
