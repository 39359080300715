export const config = {
    "build_timestamp": "2024-07-10T01:30:23.897Z",
    "geoKey": "AIzaSyBeeIWUhRhc2ZW9oKxUugzu8y9JQgFVcvA",
    "zoomSdkKey": "Egz3OjF7ITKvLvhHxRhxLNa5us9uJQ24oJX7",
    "mockServices": false,
    "env": "dhmark",
    "serverUrl": "https://dhmark.eonlineworkshop.com/dhWebsite",
    "imgUrl": "https://dhmark.eonlineworkshop.com/",
    "razorAppKey": "rzp_live_0FiGdfujDNHPj1"
};
